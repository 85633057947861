import cn from "classnames";
import { ChangeEvent, useEffect, useState } from "react";
import { useSearchBox } from "react-instantsearch";

import TextFormControl from "../../../../../atoms/text-form-control/TextFormControl";
import { useBreedSelection } from "../../../application/breed-selection-use-cases";
import * as styles from "./AlternativeBreedSelect.module.scss";

interface BreedSearchBoxProps {
  label: string;
  toggleBreedHits: () => void;
  formControlName: string;
}

const AlternativeBreedSearchBox = ({
  label,
  toggleBreedHits,
  formControlName,
}: BreedSearchBoxProps): JSX.Element => {
  const [query, setQuery] = useState("");
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [breedSelection] = useBreedSelection();
  const { refine } = useSearchBox();

  useEffect((): void => {
    setQuery(breedSelection[formControlName].name);
  }, [breedSelection, formControlName, inputIsFocused]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;

    setQuery(value);
    refine(value);
  };

  const handleClick = (): void => {
    setQuery("");
    toggleBreedHits();
  };

  const handleBlur = (): void => {
    setInputIsFocused(false);
    setTimeout(toggleBreedHits, 300);
  };

  const handleFocus = (): void => setInputIsFocused(true);

  return (
    <section className={cn(styles.breedSearchBoxWrapper)}>
      <TextFormControl
        name={formControlName}
        label={label}
        value={query}
        onChange={handleChange}
        onFocus={handleFocus}
        onClick={handleClick}
        onBlur={handleBlur}
      />
      <svg
        className={cn("icon icon--search", { [styles.inputIsFocused]: inputIsFocused })}
        aria-hidden="true"
      >
        <use href="#icon-search" />
      </svg>
    </section>
  );
};

export default AlternativeBreedSearchBox;
