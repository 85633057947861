import cn from "classnames";
import { Form, FormikHelpers, FormikProps } from "formik";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement, useState } from "react";
import * as Yup from "yup";

import Button from "../../../../../../atoms/button/Button";
import RadioButton from "../../../../../../core/ui/atoms/radio-button/RadioButton";
import RadioGroup from "../../../../../../core/ui/molecules/radio-group/RadioGroup";
import PageStepForm, {
  PageStepFormDataElement,
} from "../../../../../../organisms/page-step-form/PageStepForm";
import { PetSpecies } from "../../../../../../settings/pet";
import { Events, track } from "../../../../../../utils/analytics";
import { rollbar } from "../../../../../../utils/rollbar";
import { useBreedSelection } from "../../../../application/breed-selection-use-cases";
import { useLead, useSetLead } from "../../../../application/lead-use-cases";
import { Lead } from "../../../../domain/lead";
import AlternativeBreedSelect from "../../../molecules/breed-select/AlternativeBreedSelect";
import UnknownBreedSelector from "../../../molecules/unknown-breed-selector/UnknownBreedSelector";
import * as styles from "./Breed.module.scss";

interface PetBreedFormValues {
  knownBreed: string;
  petBreedKbKey: string | null;
  petSecondBreedKbKey: string | null;
}

interface Image {
  default: string;
}

const magnifierSVG: Image = require("../../../images/magnifier.svg") as Image;

const magnifierSVGSrc: string = magnifierSVG.default;

const Breed = (): ReactElement => {
  const { t, i18n } = useTranslation();
  const lead: Lead = useLead();
  const setLead = useSetLead();
  const nextUrl = `/${i18n.language}/onboarding/name/`;

  const [breedSelection, setBreedSelection] = useBreedSelection();
  const [showSecondBreedSelector, setShowSecondBreedSelector] = useState<boolean>(
    !!breedSelection.petSecondBreed.kbKey
  );
  const species: PetSpecies = !lead.petSpecies ? PetSpecies.dog : lead.petSpecies;

  const PetBreedFormData: PageStepFormDataElement = {
    initialValues: {
      knownBreed: breedSelection.knownBreed ?? null,
      petBreedKbKey: breedSelection.petBreed.kbKey ?? null,
      petSecondBreedKbKey: !breedSelection.petBreed.kbKey
        ? null
        : breedSelection.petSecondBreed.kbKey ?? null,
    },

    validationSchema: Yup.object({
      knownBreed: Yup.string().required(t("common.validation.required")),
      petBreedKbKey: Yup.string().trim().required(t("common.validation.required")),
      petSecondBreedKbKey: Yup.string().min(0).nullable(),
    }),
    handleSubmit: (
      values: PetBreedFormValues,
      { setSubmitting }: FormikHelpers<PetBreedFormValues>
    ): void => {
      if (!values.petBreedKbKey) {
        return;
      }

      if (values.petSecondBreedKbKey === "") {
        values.petSecondBreedKbKey = null;
      }

      setSubmitting(true);
      setLead(values)
        .then((updatedLead: Lead | undefined): void => {
          if (!updatedLead) {
            return;
          }

          track(Events.FORM_ANSWERED, {
            question: "Pet breed",
            answer: values,
          });

          void navigate(nextUrl);
        })
        .catch((): void => {
          rollbar.warn("There was an error setting the lead.");
        })
        .finally(() => setSubmitting(false));
    },
    children: (props: FormikProps<PetBreedFormValues>) => {
      const { setFieldValue, isSubmitting, values, setValues } = props;

      const handleSelectBreed = (uuid: string, name: string, formControlName: string): void => {
        setBreedSelection({ ...breedSelection, [formControlName]: { kbKey: uuid, name } })
          .then(() => setFieldValue(`${formControlName}KbKey`, uuid))
          .catch(() => {
            rollbar.warn("There was an error setting the breed selection.");
          });
      };

      const handleAnswerKnownBreed = (value: string): void => {
        track(Events.ITEM_SELECTED, { question: "Known breed", answer: value });
        setBreedSelection({
          knownBreed: value,
          petBreed: { kbKey: "", name: "" },
          petSecondBreed: { kbKey: "", name: "" },
        })
          .then(() => {
            setShowSecondBreedSelector(false);
            setValues({
              knownBreed: value,
              petBreedKbKey: null,
              petSecondBreedKbKey: null,
            });

            // To avoid an unnecessary API call, we only select the default cat breed if the species is a cat
            if (species === PetSpecies.cat && value === "no") {
              handleSelectBreed(
                "c87d5b8c-07c6-11eb-af87-c8e56b957365",
                t("unknown_breed_selector.cat.default_breed_name"),
                "petBreed"
              );
            }
          })
          .catch(() => {
            rollbar.warn("There was an error setting the breed selection.");
          });
      };

      const handleShowSecondBreedSelector = (): void => {
        track(Events.CLICKED_CTA, { eventSender: "Show Second Breed Selector CTA" });
        setShowSecondBreedSelector(true);
      };

      return (
        <div className={styles.breedWrapper}>
          <RadioGroup
            aria-label={t("onboarding.breed.known_breed.aria_label")}
            value={values.knownBreed}
            onChange={handleAnswerKnownBreed}
            adoptionClassName={styles.radioGroup}
          >
            <RadioButton value="yes">{t("common.yes")}</RadioButton>
            <RadioButton value="no">{t("common.no")}</RadioButton>
          </RadioGroup>

          {values.knownBreed === "yes" && (
            <>
              <AlternativeBreedSelect
                species={species}
                selectBreedUuid={handleSelectBreed}
                label={t("onboarding.breed.breed_select.label")}
                formControlName="petBreed"
              />
              <p className={styles.addBreedMessage}>{t("onboarding.breed.add_breed.text")}</p>
              {showSecondBreedSelector && (
                <AlternativeBreedSelect
                  species={species}
                  selectBreedUuid={handleSelectBreed}
                  label={t("onboarding.breed.breed_select.label")}
                  formControlName="petSecondBreed"
                  adoptionClassName={styles.secondBreedSelector}
                />
              )}
              {!showSecondBreedSelector && (
                <Button
                  type="button"
                  onClick={handleShowSecondBreedSelector}
                  adoptionClassName={styles.addBreedButton}
                >
                  {t("onboarding.breed.add_breed.button")}
                </Button>
              )}
            </>
          )}

          {values.knownBreed === "no" && (
            <UnknownBreedSelector
              selectBreedUuid={handleSelectBreed}
              species={species}
              adoptionClassName={styles.crossbreedSelector}
            />
          )}

          <Form className={cn(styles.petBreedForm)}>
            <Button
              type="submit"
              disabled={isSubmitting || !breedSelection.petBreed.kbKey}
              isLoading={isSubmitting}
            >
              {t("common.cta.next")}
            </Button>
          </Form>
        </div>
      );
    },
  };

  return (
    <PageStepForm
      title={t(`onboarding.breed.title.${species}`)}
      image={magnifierSVGSrc}
      formData={PetBreedFormData}
    />
  );
};

export default Breed;
