import { atom } from "recoil";

import { sessionStorageRecoilSync } from "../../../core/data/session-storage-repository";
import { BreedSelection } from "../application/breed-selection-use-cases";

export const breedSelectionState = atom<BreedSelection>({
  key: "breedSelection",
  default: {
    knownBreed: "",
    petBreed: {
      kbKey: "",
      name: "",
    },
    petSecondBreed: {
      kbKey: "",
      name: "",
    },
  },
  effects: [sessionStorageRecoilSync("breedSelection")],
});
