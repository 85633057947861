import algoliasearch from "algoliasearch/lite";
import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement } from "react";
import { Configure, InstantSearch } from "react-instantsearch";

import { availableLocales, Locale } from "../../../../../settings/countries";
import { PetSpecies } from "../../../../../settings/pet";
import CrossbreedRadioGroup from "../../atoms/crossbreed-radio-group/CrossbreedRadioGroup";
import * as styles from "./UnknownBreedSelector.module.scss";

const gastbyAlgoliaAppId = process.env.GATSBY_ALGOLIA_APP_ID ?? "";
const gastbyAlgoliaSearchKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY ?? "";
const searchClient = algoliasearch(gastbyAlgoliaAppId, gastbyAlgoliaSearchKey);

interface UnknownBreedSelectorProps {
  selectBreedUuid: (uuid: string, name: string, formControlName: string) => void;
  species: PetSpecies;
  adoptionClassName?: string;
  formControlName?: string;
}

const UnknownBreedSelector = ({
  selectBreedUuid,
  species,
  adoptionClassName,
  formControlName = "petBreed",
}: UnknownBreedSelectorProps): ReactElement => {
  const { t, i18n } = useTranslation();
  const language: Locale = availableLocales.includes(i18n.language as Locale)
    ? (i18n.language as Locale)
    : ("es" as Locale);
  const defaultCatBreedName = t("unknown_breed_selector.cat.default_breed_name");

  const selectHit = (uuid: string, name: string): void => {
    selectBreedUuid(uuid, name, formControlName);
  };

  return (
    <>
      {species === PetSpecies.dog ? (
        <InstantSearch searchClient={searchClient} indexName={`breeds_${language}_prod`}>
          <Configure filters={`species:${species}`} />
          <div className={cn(adoptionClassName)}>
            <p className={styles.weightQuestion}>{t("unknown_breed_selector.question")}</p>
            <CrossbreedRadioGroup selectHit={selectHit} language={language} />
          </div>
        </InstantSearch>
      ) : (
        <p className={styles.catMessage}>
          {t("unknown_breed_selector.cat.assign_message", { petBreed: defaultCatBreedName })}
        </p>
      )}
    </>
  );
};

export default UnknownBreedSelector;
