import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement } from "react";
import { useHits } from "react-instantsearch";

import RadioButton, {
  RadioButtonVariantsName,
} from "../../../../../core/ui/atoms/radio-button/RadioButton";
import RadioGroup from "../../../../../core/ui/molecules/radio-group/RadioGroup";
import { Locale } from "../../../../../settings/countries";
import { useBreedSelection } from "../../../application/breed-selection-use-cases";
import * as styles from "./CrossbreedRadioGroup.module.scss";

interface CrossbreedRadioGroupProps {
  selectHit: (uuid: string, name: string) => void;
  language: Locale;
}

const CrossbreedRadioGroup = ({ selectHit, language }: CrossbreedRadioGroupProps): ReactElement => {
  const { t } = useTranslation();
  const { hits } = useHits();
  const hitsList = hits.filter((hit: any) => hit.key.startsWith("a_mongrel_"));
  const [breedSelection] = useBreedSelection();

  const handleChange = (value: string): void => {
    const hit: any = hits.find((hit: any) => hit.uuid_key === value);

    if (!hit) {
      return;
    }

    selectHit(value, hit[`name_${language}`]);
  };

  return (
    <RadioGroup
      aria-label={t("crossbreed_radio_group.aria_label")}
      onChange={(value) => handleChange(value)}
      adoptionClassName={styles.radioGroup}
      {...(breedSelection.petBreed.kbKey.length && { defaultValue: breedSelection.petBreed.kbKey })}
    >
      {hitsList.map((hit: any) => (
        <RadioButton
          key={hit.key}
          value={hit.uuid_key}
          variantName={RadioButtonVariantsName.hasIcon}
          adoptionClassName={styles.radioButton}
        >
          {t(`unknown_breed_selector.weight_group.${hit.weight_group.toLowerCase()}`)}
        </RadioButton>
      ))}
    </RadioGroup>
  );
};

export default CrossbreedRadioGroup;
