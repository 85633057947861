import { graphql } from "gatsby";
import { ReactElement } from "react";

import Breed from "../../features/leads-funnel/ui/pages/onboarding/breed/Breed";
import OnboardingLayout from "../../organisms/lead/OnboardingLayout";

const BreedPage = (): ReactElement => {
  return (
    <OnboardingLayout>
      <Breed />
    </OnboardingLayout>
  );
};

export default BreedPage;

export const query = graphql`
  query BreedPageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
