import { useRecoilState } from "recoil";

import { breedSelectionState } from "../state/breed-selection-state";

type PetBreed = {
  kbKey: string;
  name: string;
};

export type BreedSelection = {
  knownBreed: string;
  petBreed: PetBreed;
  petSecondBreed: PetBreed;
};

export const useBreedSelection = (): [
  BreedSelection,
  (newValue: BreedSelection) => Promise<void>
] => {
  const [breedSelection, _setBreedSelection] = useRecoilState(breedSelectionState);

  const setBreedSelection = async (newValue: BreedSelection): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        _setBreedSelection(newValue);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  return [breedSelection, setBreedSelection];
};
